
import { Component, Mixins } from 'vue-property-decorator';

// Node module imports
import { View } from 'client-website-ts-library/plugins';

import { ListingFilter, ProfileFilter } from 'client-website-ts-library/filters';

import {
  ListingStatus,
  Office,
  MapMarker,
  WebsiteLevel,
  Profile,
  WebsiteProfileDisplayType,
} from 'client-website-ts-library/types';

import {
  API,
  Config,
  Logger,
  LogLevel,
} from 'client-website-ts-library/services';

// Vue imports
import { ItemCyclerSlide, ItemCyclerImageSlide } from '../app_code/ItemCycler';
import ItemCycler from '../components/Cycler/ItemCycler.vue';
import Listings from '../components/Listings.vue';
import ListingSearch from '../components/ListingSearch.vue';
import PhotoRow from '../components/Layout/PhotoRow.vue';
import Form from '../components/Forms/Form.vue';
import GridNavigation from '../components/UI/GridNavigation.vue';
import GridNavigationItem from '../components/UI/GridNavigationItem.vue';

@Component({
  components: {
    ItemCycler,
    Listings,
    ListingSearch,
    PhotoRow,
    Form,
    GridNavigation,
    GridNavigationItem,
  },
})
export default class Home extends Mixins(View) {
  private office: Office | null = null;

  private profiles: Profile[] | null = null;

  private modalActive = true;

  private cyclerImages: ItemCyclerSlide[] = [
    new ItemCyclerImageSlide({
      source: '/assets/images/home/slider/1',
      formats: ['avif', 'webp', 'jpg'],
      fallback: 'jpg',
    }),
    new ItemCyclerImageSlide({
      source: '/assets/images/home/slider/2',
      formats: ['avif', 'webp', 'jpg'],
      fallback: 'jpg',
    }),
    new ItemCyclerImageSlide({
      source: '/assets/images/home/slider/5',
      formats: ['avif', 'webp', 'jpg'],
      fallback: 'jpg',
    }),
    new ItemCyclerImageSlide({
      source: '/assets/images/home/slider/6',
      formats: ['avif', 'webp', 'jpg'],
      fallback: 'jpg',
    }),
  ];

  private listingFilter: ListingFilter = new ListingFilter({
    Statuses: [ListingStatus.Current, ListingStatus.UnderContract],
    PageSize: 6,
  });

  mounted() {
    this.loadOffice();
    this.loadProfiles();
  }

  loadProfiles(): void {
    API.Profiles.Search(new ProfileFilter()).then((profiles) => {
      this.profiles = profiles.filter((p) => p.DisplayType !== WebsiteProfileDisplayType.Principal);
    });
  }

  loadOffice(): void {
    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office;
    });
  }
}
